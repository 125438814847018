<template>
  <div class="fast-edit-wrapper">
    <b-card>
      <b-alert
        v-if="errors && errors.length"
        variant="danger"
        show
        dismissible
        fade
      >
        <div
          v-for="(error, i) in errors"
          :key="`${i}`"
        >
          {{ error }}
        </div>
      </b-alert>
      <b-row>
        <b-col
          v-for="field in fields"
          :key="`${field.key}-${verSfx}`"
          :class="field && field.class || 'col-3'"
        >
          <b-form-group
            :id="field.key"
            :label="!isSwitchField(field) && !isPreviewField(field) ? field.label : ''"
            :label-for="`fast-edit-input-${field.key}`"
            label-size="sm"
          >
            <p
              v-if="isPreviewField(field)"
              v-shtml="rawData[field.key]"
            />
            <b-form-select
              v-else-if="isSelectField(field)"
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
              :options="formData[field.key]"
              label-field="value"
              value-field="id"
              size="sm"
            />
            <b-form-datepicker
              v-else-if="isDateField(field)"
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
              :options="formData[field.key]"
              size="sm"
            />
            <b-form-input
              v-else-if="isNumberField(field)"
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
              type="number"
              size="sm"
            />
            <b-form-textarea
              v-else-if="isTextAreaField(field)"
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
            />
            <input
              v-else-if="isHiddenField(field)"
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
              type="hidden"
            >
            <client-only
              v-else-if="isHtmlEditorField(field)"
            >
              <quill-editor
                :id="`fast-edit-input-${field.key}`"
                v-model="rawData[field.key]"
                class="q-editor"
              />
            </client-only>
            <b-form-checkbox
              v-else-if="isSwitchField(field)"
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
              size="sm"
              class="mr-2"
              switch
            >
              {{ field.label }}
            </b-form-checkbox>
            <b-form-input
              v-else
              :id="`fast-edit-input-${field.key}`"
              v-model="rawData[field.key]"
              trim
              size="sm"
              @keyup.enter="() => { onSubmit(); }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-inline-flex justify-content-end">
          <b-btn size="sm" @click="() => { onClear(); }">
            {{ $t('eDt_btnClear') }}
          </b-btn>
          <b-btn variant="primary" class="ml-2" size="sm" @click="() => { onSubmit(); }">
            {{ $t('eDt_btnSubmit') }}
          </b-btn>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DataFastEdit',
  props: {
    repo: {
      type: String,
      required: true,
      default: ''
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    itemId: {
      type: [Number, String, null],
      default: 0
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      currentItemId: this.itemId,
      rawData: {},
      initData: {},
      verSfx: 0
    }
  },
  async fetch () {
    await this.call({
      repo: this.repo,
      method: 'edit',
      id: this.currentItemId,
      query: this.query
    })
    await this.call({
      repo: this.repo,
      method: 'show',
      id: this.currentItemId,
      query: this.query
    })
    this.$nextTick(() => {
      const rawData = this.getByName(`${this.repo}/show`)
      if (rawData && rawData.data) {
        if (typeof rawData.data.id !== 'undefined') {
          this.currentItemId = rawData.data.id
        }
        for (const field of this.fields) {
          if (typeof rawData.data[field.key] !== 'undefined') {
            this.rawData[field.key] = rawData.data[field.key]
          }
        }
      }
      this.initData = Object.assign({}, this.rawData)
    })
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      errors: 'repos/errors'
    }),
    dataFields () {
      return this.fields && this.fields.filter((field) => {
        return field && field.filter
      })
    },
    formData () {
      const obj = this.getByName(`${this.repo}/edit`)
      return obj && obj.data ? obj.data : []
    },
    dirty () {
      const dirty = {}
      for (const field of this.fields) {
        if (this.rawData[field.key] !== this.initData[field.key]) {
          dirty[field.key] = this.rawData[field.key]
        }
      }
      return dirty
    }
  },
  watch: {
    query: {
      async handler () {
        await this.$fetch()
        this.$forceUpdate()
      },
      deep: true
    }
  },
  async mounted () {
    this.flushError()
    for (const field of this.fields) {
      this.rawData[field.key] = null
      if (typeof field.default !== 'undefined') {
        this.rawData[field.key] = field.default
      }
    }
    await this.$fetch()
    this.$forceUpdate()
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError'
    }),
    isSelectField (field) {
      return field &&
        field.type === 'select' &&
        this.formData &&
        this.formData[field.key]
    },
    isDateField (field) {
      return field &&
        field.type === 'date'
    },
    isNumberField (field) {
      return field &&
        field.type === 'number'
    },
    isTextAreaField (field) {
      return field && field.type === 'textarea'
    },
    isHtmlEditorField (field) {
      return field && field.type === 'editor'
    },
    isSwitchField (field) {
      return field && field.type === 'switch'
    },
    isPreviewField (field) {
      return field && field.type === 'preview'
    },
    isHiddenField (field) {
      return field && field.type === 'hidden'
    },
    getItemLabel (item) {
      let label = ''
      if (item && item.column) {
        label = item.column
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          label = field.label
        }
      }
      return label
    },
    getItemValue (item) {
      let value = item.value
      if (item && item.column) {
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          if (this.isSelectField(field)) {
            const option = this.formData[item.column].find(option => option.id === item.value)
            if (option) {
              value = option.text
            }
          }
        }
      }
      return value
    },
    onClear () {
      for (const field of this.fields) {
        this.rawData[field.key] = this.initData[field.key]
      }
      this.$nextTick(() => {
        this.verSfx += 1
        this.$forceUpdate()
      })
    },
    async onSubmit () {
      await this.call({
        repo: this.repo,
        method: 'update',
        id: this.currentItemId,
        payload: this.rawData
      })
      if (!this.errors || this.errors.length === 0) {
        this.$emit('on-submit', this.rawData)
        this.$nextTick(async () => {
          await this.$fetch()
          this.$forceUpdate()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.wrapper {
  .quill-editor .ql-container {
    max-height: 50svh;
    overflow: hidden;
    overflow-y: auto;
  }
  &.mobile {
    .quill-editor .ql-container {
      max-height: 40svh;
    }
  }
}
</style>
