<template>
  <div
    ref="wrapper"
    class="table-wrapper"
  >
    <div
      v-if="items && items.data"
      :id="`dt_${dtKey}`"
      ref="datacards"
      class="cards row m-0 p-0"
      :style="`max-height:${scrollHeight}; `"
    >
      <div
        v-for="(item, i) in items.data"
        :key="i"
        class="col-12 col-md-6 col-lg-4 px-0 px-md-1 px-lg-2"
      >
        <b-card
          class="mb-2 mb-md-3 mb-lg-4"
          :no-body="collapse ? !states[item.id] : false"
          :border-variant="itemVariant(item)"
          :header-bg-variant="itemVariant(item)"
        >
          <template
            v-if="collapse || canBatch || hasSlot('card(header)') || hasSlot('card(actions)')"
            #header
          >
            <div class="row">
              <div
                v-if="collapse || canBatch"
                class="col-2 col-lg-1 d-flex flex-column align-items-center justify-content-between"
              >
                <b-button
                  v-if="collapse"
                  size="sm"
                  variant="outline-secondary"
                  @click="onToggleCard(item.id)"
                >
                  <font-awesome-icon
                    :icon="!!states[item.id] ? 'chevron-up' : 'chevron-down'"
                    size="sm"
                  />
                </b-button>
                <slot
                  v-if="canBatch"
                  :name="`cell(batch)`"
                  :data="{ item: item }"
                />
              </div>
              <div
                class="text-wrap"
                :class="cardHeaderCls"
              >
                <slot
                  name="card(header)"
                  :data="{ item, index: i }"
                />
              </div>
              <div
                v-if="hasSlot('card(actions)')"
                class="col-2 col-lg-1 text-right pr-1 position-relative"
              >
                <b-dropdown
                  size="sm"
                  variant="outline-none"
                  no-caret
                  right
                  dropleft
                >
                  <template #button-content>
                    <font-awesome-icon :icon="['fa', 'ellipsis-vertical']" />
                  </template>
                  <slot
                    name="card(actions)"
                    :data="{ item, index: i }"
                  >
                    {{ item['actions'] }}
                  </slot>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template
            v-if="collapse ? !!states[item.id] : true"
            #default
            class="px-0"
          >
            <slot
              name="card(body)"
              :data="{ item, index: i }"
            >
              <div
                v-for="(field, j) in fields"
                :key="j"
                class="row my-2"
              >
                <div
                  v-if="field.key !== 'actions'"
                  class="col-1"
                >
                  <span
                    v-if="isOrderBy(field.key)"
                    class="text-info"
                  >
                    <font-awesome-icon
                      :icon="orderIcon()"
                      size="sm"
                    />
                  </span>
                </div>
                <div
                  v-if="field.key !== 'actions'"
                  class="text-wrap"
                  :class="cellLabelCls(field)"
                >
                  <strong v-shtml="field.label" />:
                </div>
                <div
                  v-if="field.key !== 'actions'"
                  class="text-wrap"
                  :class="cellValueCls(field)"
                >
                  <slot
                    :name="`cell(${field.key})`"
                    :data="{ item, index: j, field }"
                  >
                    <span :key="`${field.key}_${item.id}`">{{
                      typeof field.formatter === 'function'
                        ? field.formatter(item[field.key], j, item)
                        : item[field.key]
                    }}</span>
                  </slot>
                </div>
                <div
                  v-if="!hasSlot('card(actions)') && field.key === 'actions'"
                  class="col-12 text-wrap pt-2"
                >
                  <slot
                    :name="`cell(${field.key})`"
                    :data="{ item, index: j, field }"
                  >
                    <span :key="`${field.key}_${item.id}`">{{ item[field.key] }}</span>
                  </slot>
                </div>
              </div>
            </slot>
          </template>
        </b-card>
      </div>
      <div class="col-12" />
    </div>
    <slot
      v-if="items && items.data"
      name="pagination"
      :items="items"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'DataCards',
  props: {
    repo: {
      type: String,
      default: ''
    },
    parentId: {
      type: [String, Number, null],
      default: () => null
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    order: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ''
    },
    setItemVariant: {
      type: [String, Function],
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    delayedCall: {
      type: Boolean,
      default: false
    },
    limit: {
      type: [Boolean, Number, String],
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    layout: {
      type: String,
      default: 'table'
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dtKey: null,
      busy: false,
      currentPage: parseInt(this.page, 10) || 1,
      pageSize: parseInt(this.limit, 10) || 10,
      sort: null,
      scrollHeight: `${this.height}px`,
      isMounted: false,
      skipReload: false,
      states: {}
    }
  },
  async fetch () {
    if (this.skipReload) {
      this.skipReload = false
      return
    }
    const query = {
      page: this.currentPage,
      limit: this.pageSize
    }
    if (this.useLimit) {
      query.limit = this.pageSize
    }
    if (this.sort) {
      query['order[by]'] = this.sort.by
      query['order[dir]'] = this.sort.dir
    } else if (this.order && this.order.by && this.order.dir) {
      this.sort = {
        by: this.order.by,
        dir: this.order.dir
      }
      query['order[by]'] = this.sort.by
      query['order[dir]'] = this.sort.dir
    }
    if (this.filter && this.filter.length) {
      for (const filter of this.filter) {
        if (typeof filter.value === 'object') {
          for (const key of Object.keys(filter.value)) {
            query[`filter[${filter.column}][${key}]`] = filter.value[key]
          }
        } else {
          query[`filter[${filter.column}]`] = filter.value
        }
      }
    }
    if (this.search && this.search !== '') {
      query.q = this.search
    }
    if (this.delayedCall) {
      this.addCall({
        repo: this.repo,
        method: 'index',
        parentId: this.parentId,
        query
      })
      if (this.isMounted) {
        this.$emit('on-call-added')
        // this.updateSort()
      }
    } else {
      try {
        this.busy = true
        if (!isServer) {
          this.$nuxt.$loading.start()
        }
        await this.call({
          repo: this.repo,
          method: 'index',
          parentId: this.parentId,
          query
        })
        this.currentPage = parseInt(this.pager.current_page, 10)
      } finally {
        if (!isServer) {
          this.$nuxt.$loading.finish()
        }
        this.busy = false
        // this.updateSort()
      }
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device'
    }),
    items () {
      return this.getByName(`${this.repo}/index`)
    },
    tableFields () {
      return this.fields.map((field) => {
        const newField = {}
        Object.keys(field).filter(k => k !== 'sortable').forEach((k) => {
          newField[k] = field[k]
        })
        return newField
      })
    },
    pager () {
      let pager = {
        count: 0,
        current_page: 0,
        links: [],
        per_page: 0,
        total: 0,
        total_pages: 0
      }
      if (this.items) {
        if (this.items?.meta?.pagination) {
          pager = this.items.meta.pagination
        } else {
          pager = {
            count: this.items.count,
            current_page: this.items.current_page,
            links: this.items.links,
            per_page: this.items.per_page,
            total: this.items.total,
            total_pages: this.items.total_pages
          }
        }
      }
      return pager
    },
    pagerPageSize () {
      return this.pager?.per_page || 10
    },
    locale () {
      return this.$i18n.locale
    },
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    cardHeaderCls () {
      let cls = ''
      let clsSm = 12
      let clsLg = 12
      if (this.collapse || this.canBatch) {
        clsSm -= 2
        clsLg -= 1
      }
      if (this.hasSlot('card(actions)')) {
        clsSm -= 2
        clsLg -= 1
      }
      if (this.collapse || this.canBatch) {
        cls += 'pl-2 pl-lg-3'
      }
      return `col-${clsSm} col-lg-${clsLg} ${cls}`
    },
    canBatch () {
      return this.hasSlot('cell(batch)')
    }
  },
  watch: {
    page (n) {
      this.currentPage = n
    },
    limit (n) {
      this.pageSize = n
    },
    async currentPage (n) {
      if (n) {
        await this.$fetch()
        this.onPageChanged(n)
      }
    },
    pageSize (n) {
      if (n) {
        this.currentPage = 1
        this.$nextTick(async () => {
          await this.$fetch()
          this.onPageChanged(1)
        })
      }
    },
    pagerPageSize (n) {
      if (this.pageSize !== n) {
        // skip double load if server side page size changed
        this.skipReload = true
        this.pageSize = n
        this.$nextTick(() => {
          this.skipReload = false
        })
      }
    },
    filter: {
      handler () {
        this.$fetch()
      },
      deep: true
    },
    order: {
      async handler (v) {
        if (this.sort.by !== v.by || this.sort.dir !== v.dir) {
          this.sort = {
            by: v.by,
            dir: v.dir
          }
          await this.$fetch()
        }
        // this.$emit('on-sort', this.sort)
      },
      deep: true
    },
    locale () {
      this.$fetch()
    },
    height () {
      this.calcHeight()
    }
  },
  mounted () {
    this.dtKey = (Math.random() + 1).toString(36).substring(8)
    this.calcHeight()
    this.isMounted = true
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      addCall: 'repos/addCall',
      flush: 'repos/flush'
    }),
    onUpdate () {
      this.$fetch()
      if (this.delayedCall) {
        this.$emit('on-call-added')
      }
    },
    async onSort (e, column) {
      const field = this.fields.find(i => i.key === column)
      if (field && field.sortable) {
        const cols = document.querySelectorAll(`#dt_${this.dtKey} [aria-sort]`)
        for (const col of cols) {
          col.ariaSort = 'none'
        }
        const dir = this.sort && this.sort.by === field.key
          ? (this.sort.dir.toLowerCase() === 'asc' ? 'desc' : 'asc')
          : 'asc'
        this.sort = {
          by: field.key,
          dir
        }
        await this.$fetch()
        this.$emit('on-sort', this.sort)
      }
    },
    getById (id) {
      return this.items && this.items.data.find(i => i.id === id)
    },
    getMeta () {
      return this.items && this.items.meta
    },
    calcHeight () {
      if (this.$refs.wrapper && this.$refs.pager && window) {
        const pagerHeight = this.$refs.pager?.$el?.offsetHeight || 0
        this.scrollHeight = `${this.height - pagerHeight}px`
      } else {
        this.scrollHeight = `${this.height}px`
      }
    },
    onPageChanged (page) {
      this.currentPage = page
      if (this.$refs.datacards) {
        this.$refs.datacards.scrollTo({ top: 0 })
      }
    },
    onPageSizeChanged (size) {
      this.pageSize = size
    },
    hasSlot (name) {
      return !!this.$parent.$slots[name] ||
        !!this.$parent.$scopedSlots[name] ||
        !!this.$slots[name] ||
        !!this.$scopedSlots[name]
    },
    isOrderBy (key) {
      if (this.order?.by === key) {
        return true
      }
      return false
    },
    orderIcon () {
      if (this.order?.dir === 'asc') {
        return 'arrow-down-a-z'
      }
      return 'arrow-up-z-a'
    },
    onToggleCard (id) {
      if (typeof this.states[id] === 'undefined') {
        this.states[id] = true
      } else {
        this.states[id] = !this.states[id]
      }
      this.$forceUpdate()
    },
    cellLabelCls (field) {
      if (field?.cardFullWidth) {
        return 'col-11'
      }
      if (field.cardNoLabel) {
        return 'd-none'
      }
      return 'col-4 col-lg-3'
    },
    cellValueCls (field) {
      if (field?.cardFullWidth) {
        return 'col-11 offset-1 mt-2 pl-0'
      }
      if (field.cardNoLabel) {
        return 'col-11 pl-0'
      }
      return 'col-7 col-lg-8'
    },
    itemVariant (item) {
      return typeof this.setItemVariant === 'function'
        ? this.setItemVariant(item)
        : this.setItemVariant
    },
    collapseCards () {
      if (this.items?.data?.length) {
        this.items.data.forEach((i) => {
          this.states[i.id] = false
        })
        this.$forceUpdate() //
      }
    },
    expandCards () {
      if (this.items?.data?.length) {
        this.items.data.forEach((i) => {
          this.states[i.id] = true
        })
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss">
.table-wrapper {
  .cards {
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
