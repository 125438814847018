<template>
  <div class="fast-add-wrapper">
    <b-card>
      <b-alert
        v-if="errors && errors.length"
        variant="danger"
        show
        dismissible
        fade
      >
        <div
          v-for="(error, i) in errors"
          :key="i"
        >
          {{ error }}
        </div>
      </b-alert>
      <b-row>
        <b-col
          v-for="field in fields"
          :key="field.key"
          :class="field && field.class || 'col-3'"
        >
          <b-form-group
            :id="field.key"
            :label="field.label"
            :label-for="`fast-add-input-${field.key}`"
            label-size="sm"
          >
            <b-form-select
              v-if="isSelectField(field)"
              :id="`fast-add-input-${field.key}`"
              v-model="rawData[field.key]"
              :options="formData[field.key]"
              label-field="value"
              value-field="id"
              size="sm"
            />
            <b-form-datepicker
              v-else-if="isDateField(field)"
              :id="`fast-add-input-${field.key}`"
              v-model="rawData[field.key]"
              :options="formData[field.key]"
              size="sm"
            />
            <b-form-input
              v-else-if="isNumberField(field)"
              :id="`fast-add-input-${field.key}`"
              v-model="rawData[field.key]"
              type="number"
              size="sm"
            />
            <b-form-input
              v-else
              :id="`fast-add-input-${field.key}`"
              v-model="rawData[field.key]"
              trim
              size="sm"
              @keyup.enter="() => { onSubmit(); }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-inline-flex justify-content-end">
          <b-btn size="sm" @click="() => { onClear(); }">
            {{ $t('eDt_btnClear') }}
          </b-btn>
          <b-btn variant="primary" class="ml-2" size="sm" @click="() => { onSubmit(); }">
            {{ $t('eDt_btnSubmit') }}
          </b-btn>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DataFastAdd',
  props: {
    repo: {
      type: String,
      required: true,
      default: ''
    },
    fields: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      rawData: {}
    }
  },
  async fetch () {
    await this.call({
      repo: this.repo,
      method: 'create'
    })
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      errors: 'repos/errors'
    }),
    dataFields () {
      return this.fields && this.fields.filter((field) => {
        return field && field.filter
      })
    },
    formData () {
      return this.getByName(`${this.repo}/create`) || []
    }
  },
  watch: {},
  async mounted () {
    this.flushError()
    await this.$fetch()
    for (const field of this.fields) {
      this.rawData[field.key] = null
    }
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError'
    }),
    isSelectField (field) {
      return field &&
        field.type === 'select' &&
        this.formData &&
        this.formData[field.key]
    },
    isDateField (field) {
      return field &&
        field.type === 'date'
    },
    isNumberField (field) {
      return field &&
        field.type === 'number'
    },
    getItemLabel (item) {
      let label = ''
      if (item && item.column) {
        label = item.column
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          label = field.label
        }
      }
      return label
    },
    getItemValue (item) {
      let value = item.value
      if (item && item.column) {
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          if (this.isSelectField(field)) {
            const option = this.formData[item.column].find(option => option.id === item.value)
            if (option) {
              value = option.text
            }
          }
        }
      }
      return value
    },
    onClear () {
      this.rawData = {}
    },
    async onSubmit () {
      await this.call({
        repo: this.repo,
        method: 'store',
        payload: this.rawData
      })
      if (!this.errors || this.errors.length === 0) {
        this.$emit('on-submit', this.rawData)
        this.$nextTick(() => {
          for (const field of this.fields) {
            this.rawData[field.key] = null
          }
          this.$forceUpdate()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
